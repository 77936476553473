import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import classNames from 'classnames';

import SiteContainer from '../../SiteContainer';
import { Wrapper, Heading } from '../common';

import spriteCross from './sprite.cross.png';
import spriteLight from './sprite.light.png';
import spritePop from './sprite.pop.png';
import spriteSparkle from './sprite.sparkle.png';

const HeroContainer = styled(Wrapper)`
     background-color: #007bff;
     background-image: url(https://www.hostjane.com/assets/hostjane-front-bck.svg), linear-gradient(180deg, #303fd9 0, #031b4e) !important;
	 background-size: cover;
     background-position: center;
     color: #fff;

	${Heading} {
		color: #fff;
		font-size: 45px;
		line-height: 55px;
	}
	padding-top: 80px;
	padding-bottom: 80px;
	display: none;
	@media screen and (min-width: 768px) {
		display: block;
		padding-top: 60px;
		padding-bottom: 120px;
		${Heading} {
			color: #fff;
			font-size: 50px;
		}
	}
`;

const ColumnContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
`;

const Column = styled.aside`
	width: 100%;
	flex: 0 0 100%;
	margin-bottom: 50px;
	position: relative;
	&:last-child {
		margin-bottom: 0;
	}

	.hostjane-hero-column__icon {
		width: 90px;
		height: auto;
		margin: 0 auto 5px;
		svg {
			width: 90px;
			height: auto;
		}
		position: relative;
		z-index: 2;
		&.animated {
			animation: hj-hero-float 2.3s ease infinite forwards;
		}
	}
	@keyframes hj-hero-float {
		0% {
			transform: translateZ(0);
		}
		40% {
			transform: translate3d(0, -6px, 0);
		}
		100% {
			transform: translateZ(0);
		}
	}

	.hostjane-hero-column__shadow {
		width: 100%;
		max-width: 100px;
		height: 28px;
		margin: 0 auto 10px;
		background: radial-gradient(
			ellipse 38% 30%,
			rgba(0, 0, 0, 0.16),
			transparent
		);
		&.animated {
			animation: hj-hero-floatShadow 2.3s infinite forwards;
		}
	}
	@keyframes hj-hero-floatShadow {
		0% {
			opacity: 1;
		}
		40% {
			opacity: 0.8;
		}
		100% {
			opacity: 1;
		}
	}

	.hostjane-hero-column__title {
		font-size: 33px;
		font-weight: 600;
		margin: 0;
		padding: 0;
		text-align: center;
		line-height: 1.5;
		@media screen and (min-width: 768px) {
			font-size: 25px;
		}
	}

	.hostjane-hero-column__description {
		font-size: 23px;
		font-weight: 400;
		margin: 0;
		padding: 18px 12px;
		text-align: center;
		line-height: 1.5;

		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.tablet}px) {
			font-weight: 400;
			font-size: 22px;
		}
	}

	@media screen and (min-width: 768px) {
		width: 290px;
		flex: 0 0 290px;
		padding: 0 20px;
		border-right: 2px solid rgba(240, 240, 240, 0.3);
		margin: 0;

		&:last-child {
			border-right: 0 none;
		}
	}
	@media screen and (min-width: 990px) {
		max-width: 400px;
		flex-basis: 400px;
		padding-left: 80px;
		padding-right: 80px;
	}

	/** Sparkles */
	.sprite {
		display: block;
		position: absolute;
		background-position: 0;
		opacity: 0.75;
		z-index: 1;

		&.pop {
			width: 24px;
			height: 24px;
			background-image: url(${spritePop});
			background-size: 840px 24px;
			background-position: -816px;
			animation: hj-hero-pop 2.91666666667s steps(34, start) infinite
				forwards;

			&.one {
				top: 100px;
				left: 50%;
				margin-left: -100px;
				animation-delay: 0;
			}
		}
		@keyframes hj-hero-pop {
			0% {
				background-position: 0;
			}
			40% {
				background-position: -816px;
			}
			100% {
				background-position: -816px;
			}
		}

		&.sparkle {
			width: 43px;
			height: 43px;
			background-image: url(${spriteSparkle});
			background-size: 774px 43px;
			background-position: -731px;
			animation: hj-hero-sparkle 1.5s steps(17, start) infinite forwards;

			&.one {
				top: -30px;
				left: 50%;
				margin-left: -80px;
				animation-delay: 0.6s;
			}
			&.two {
				top: 80px;
				right: 50%;
				margin-right: -130px;
				animation-delay: 2.4s;
			}
		}
		@keyframes hj-hero-sparkle {
			0% {
				background-position: 0;
			}
			40% {
				background-position: -731px;
			}
			100% {
				background-position: -731px;
			}
		}

		&.light {
			width: 20px;
			height: 20px;
			background-image: url(${spriteLight});
			background-size: 620px 20px;
			background-position: -600px;
			animation: hj-hero-light 2.58333333333s steps(30, start) infinite
				forwards;

			&.one {
				top: 100px;
				right: 50%;
				margin-right: -10px;
				animation-delay: 1.4s;
			}
			&.two {
				top: 0px;
				right: 50%;
				margin-right: -80px;
				animation-delay: 2s;
			}
		}
		@keyframes hj-hero-light {
			0% {
				background-position: 0;
			}
			40% {
				background-position: -600px;
			}
			100% {
				background-position: -600px;
			}
		}

		&.cross {
			width: 25px;
			height: 25px;
			background-image: url(${spriteCross});
			background-size: 625px 25px;
			background-position: -600px;
			animation: hj-hero-cross 2.083325s steps(23, start) infinite
				forwards;

			&.one {
				top: 43px;
				left: 50%;
				margin-left: -150px;
				animation-delay: 1.8s;
			}
		}
		@keyframes hj-hero-cross {
			0% {
				background-position: 0;
			}
			40% {
				background-position: -600px;
			}
			100% {
				background-position: -600px;
			}
		}
	}
`;

const HeroCtas = styled.div`
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	.hostjane-hero-cta {
		width: 100%;
		max-width: 320px;
		height: 48px;
		padding: 0 ${props => props.theme.gutter.small / 2}px;
		display: flex;
		justify-content: stretch;
		align-items: center;
		margin: 0 auto 15px;
		background: #fff;
		border: 1px solid #fff;
		color: #000000;
		border-radius: 24px;
		font-size: 17px;
		font-weight: 600;
		text-decoration: none;
		position: relative;

		@media screen and (min-width: 768px) {
			font-size: 17px;
		}

		.hostjane-hero-cta__label {
			flex: 0 0 100%;
			text-align: center;
		}
		.hostjane-hero-cta__icon {
			width: 30px;
			height: 30px;
			font-size: 30px;
			position: absolute;
			left: 10px;
			top: 8px;
			display: block;

			&::before {
				content: '';
				width: 0;
				height: 0;
				display: block;
				border-radius: 50%;
				border: 15px solid #fff;
			}

			&::after {
				content: '';
				width: 0;
				height: 0;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				vertical-align: middle;
				position: absolute;
				top: 10px;
				left: 12px;
				border-left: 7px solid #9da3a6;
			}
		}

		&.hostjane-hero-cta--secondary {
			background-color: transparent;
			color: ${props => props.theme.neutrals.lightest};
		}

		&:hover {
			text-decoration: underline;
		}
	}
`;

function HeroCta({ type, label, link, internal }) {
	const className = classNames(
		'hostjane-hero-cta',
		`hostjane-hero-cta--${type}`
	);
	const inner = (
		<>
			{type === 'secondary' ? (
				<span className="hostjane-hero-cta__icon" />
			) : null}
			<span className="hostjane-hero-cta__label">{label}</span>
		</>
	);
	return internal ? (
		<Link to={link} className={className}>
			{inner}
		</Link>
	) : (
		<a href={link} className={className}>
			{inner}
		</a>
	);
}

function HeroColumn({ columnData, hasSparkles = true }) {
	return (
		<Column>
			<div
				className={classNames('hostjane-hero-column__icon', {
					animated: hasSparkles,
				})}
			>
				<columnData.icon />
			</div>
			<div
				className={classNames('hostjane-hero-column__shadow', {
					animated: hasSparkles,
				})}
			/>
			<h3 className="hostjane-hero-column__title">{columnData.title}</h3>
			<p className="hostjane-hero-column__description">
				{columnData.description}
			</p>
			<HeroCtas className="hostjane-hero-column__ctas">
				{columnData.ctas.map((cta, index) => (
					// eslint-disable-next-line react/no-array-index-key
					<HeroCta key={index} {...cta} />
				))}
			</HeroCtas>
			{hasSparkles ? (
				<>
					<i className="sprite pop one" />
					<i className="sprite sparkle one" />
					<i className="sprite sparkle two" />
					<i className="sprite light one" />
					<i className="sprite light two" />
					<i className="sprite cross one" />
				</>
			) : null}
		</Column>
	);
}

export default function Hero({ data, id }) {
	return (
		<HeroContainer id={id}>
			<SiteContainer>
				<Heading hero>{data.title}</Heading>
				<ColumnContainer>
					<HeroColumn columnData={data.leftColumn} />
					<HeroColumn
						columnData={data.rightColumn}
						hasSparkles={false}
					/>
				</ColumnContainer>
			</SiteContainer>
		</HeroContainer>
	);
}
